  <div class=" login-page font" [style.backgroundImage]="'url(' + backgroundImage + ')'" style="height: 100vh;">

      <div class="row justify-content-center w-100 pd-10">

        <div class="login-box col-sm-6 col-md-4 col-10">

            <h4 class="f-w-700 f-s-24 m-0">Bem-vindo!</h4>

           
            <form class="m-t-30" [formGroup]="myForm" (ngSubmit)="login()" [ngStyle]="phone ? {'width':'300px'}:{'width':'350px'}" >
           
              <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block" style="font-family: poppins-regular !important;"
                >E-mail</mat-label >
              <mat-form-field appearance="outline" class="w-100" color="primary" >
                <input matInput type="text" formControlName="email" style="font-family: poppins-regular !important; " />
                @if(f['email'].touched && f['email'].invalid) {
                <mat-hint class="m-b-16 error-msg">
                  @if(f['email'].errors && f['email'].errors['required']) {
                  <div class="text-error">campo requerido.</div>
                  } 
                @if(f['email'].errors && f['email'].errors['pattern']) {
                  <div class="text-error font fs-9">Não possui um formato de e-mail válido.</div>
                  }
                
                </mat-hint>
                }
              </mat-form-field>


              <!-- password -->
              <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block " style="font-family: poppins-regular !important; margin-top: 30px;"
                >Senha</mat-label>
              <mat-form-field appearance="outline" class="w-100" color="primary" >
                <input matInput [type]="confirmVisible ? 'text' : 'password'" formControlName="password" style="font-family: poppins-regular !important;"/>
                <button type="button" mat-icon-button  class="eye" (click)="togglePasswordVisibility('confirm')">
                  <mat-icon>{{ confirmVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
              </button> 

                @if(f['password'].touched && f['password'].invalid) {
                <mat-hint class="m-b-16 error-msg">
                  @if( f['password'].errors && f['password'].errors['required'])
                  {
                  <div class="text-error">campo requerido.</div>
                  }
                </mat-hint>
                }
              </mat-form-field>

           
              <button  type="submit" class="acceptButton w-100 mt-30"  >
                Enviar
              </button>
              <!-- input -->
              
              <div class="d-flex justify-content-end align-items-center m-b-12 mt-20 w-100">
                <a  [routerLink]="['/autenticacao/recuperar-senha']"  class="text-primary f-w-600 text-decoration-none m-l-auto f-s-14"
                >Esqueceu sua senha?</a>
                
              </div>
              <div class="d-flex justify-content-end w-100 align-items-center m-b-12 mt-20 ">
                <span class="d-block " style="font-family: poppins-regular !important;"
                  >Não tem uma conta?
                  &nbsp;
                  <a  [routerLink]="['/autenticacao/registro']" class="text-decoration-none text-primary f-w-500 f-s-14" style="font-family: poppins-regular !important;"
                  > <b>Criar uma conta</b> </a
                  >
                </span>
              </div>  

              <span class="d-block w-100 font fs-10" style="color: rgb(197, 197, 197); text-align: end;"> Version 1.2.6</span>
            </form>
          </div>
      </div>
  </div>



<!-- entre em contacto  -->
    <div class="toggle-container d-flex flex-column shadow" [ngClass]="{ 'expanded': isDivVisible }">
      <div class="d-flex justify-content-between font fs-10 p-10" >
        <p >Entre em contato conosco</p>  
        <span class="material-icons cursor" (click)="toggleDiv()" *ngIf="!position">add</span>
        <span class="material-icons cursor" (click)="toggleDiv()" *ngIf="position">remove</span>
      </div>

      <div class="d-flex justify-content-center " style="background-color: white; padding: 3px;">

        <form [formGroup]="myForm2" class="needs-validation font fs-10 w-100 pd-20" >

           <div class="w-100" >
            <mat-label class="font f-w-600 m-b-8 d-block" style="color: black;">Nome <b style="color: red;">*</b> </mat-label>
            <mat-form-field appearance="outline" class="w-100" color="primary"  >
            <input matInput type="text"  formControlName="fullName"   />
            @if(f2['fullName'].touched && f2['fullName'].invalid) {
                <mat-hint class="m-b-16 error-msg">
                @if(f2['fullName'].errors && f2['fullName'].errors['required']) {
                <div class="error">Dado obrigatorio.</div>
                } 
                </mat-hint>
                }
            </mat-form-field>
           </div>

          <div class="w-100 mt-10" >
            <mat-label class="font f-w-600 m-b-8 d-block" style="color: black;"> Ordem </mat-label>
            <select class="select" formControlName="headquarter" style="height: 42px !important;" >
              <option selected="selected"> </option>
              <option> &nbsp; Ordem primeira</option>
              <option> &nbsp; Ordem segunda</option>
            </select>
            <div *ngIf="validField2('headquarter')" class="error w-100 text-end" > Dado obrigatório </div>
          
          </div>

          <div class="w-100 mt-20" >
            <mat-label class="font f-w-600 m-b-8 d-block" style="color: black;"> Digite seu email <b style="color: red;">*</b> </mat-label>
            <mat-form-field appearance="outline" class="w-100" color="primary"  >
            <input matInput type="email"  formControlName="userEmail"   />
            @if(f2['userEmail'].touched && f2['userEmail'].invalid) {
                <mat-hint class="m-b-16 error-msg">
                @if(f2['userEmail'].errors && f2['userEmail'].errors['required']) {
                <div class="error">Dado obrigatório / Não tem formato de e-mail.</div>
                } 
                </mat-hint>
                }
            </mat-form-field>
          </div>

          <div class="w-100 mt-30" >
          <mat-label class="font f-w-600 m-b-8 d-block" style="color: black;"> Assunto <b style="color: red;">*</b></mat-label>
          <textarea  class="w-100 pd-10" formControlName="subject" (input)="limitCharacters($event)"></textarea>
          <div class="d-flex justify-content-end w-100 mt-1 fs-8 font" [ngStyle]="{'color': remainingCharacters === 0 ? 'red' : 'black'}" >{{ remainingCharacters }} de 250 caracteres restantes</div>
            <div *ngIf="validField2('subject')" class="error w-100 text-end" > Dado obrigatório </div>

         </div>

          <div class="custom-control custom-switch d-flex  align-items-center justify-content-start mt-30" >
            <input type="checkbox" class="custom-control-input cursor " id="customSwitch1"  (change)="sendMeAnEmail($event)" [checked]="sendMeACopy" >
            <label class="custom-control-label cursor font fs-10" for="customSwitch1" style="color: black; font-weight: 400; margin-left: 8px;" > Envie-me uma cópia para o meu email </label>
          </div>

          <div class="d-grid gap-2 mt-30">
            <button type="submit" class="acceptButton"  (click)="contactUs()" *ngIf="!isSending"> Enviar</button>

            <button class="acceptButton"  type="button" disabled *ngIf="isSending">
              <span class="spinner-border spinner-border-sm " role="status" aria-hidden="true" ></span>
              Enviando...
            </button>
          
          </div>
        </form>
      </div>
    </div>



    <div class="loading-overlay" *ngIf="isLoading">
      <div class="spinner-grow " role="status" style="width: 50px; height: 50px;"  >
        <mat-spinner></mat-spinner>
      </div>
    </div> 
    
